import React from 'react'
import './App.scss'
import 'primereact/resources/primereact.min.css'
import 'primeicons/primeicons.css'
import 'primereact/resources/themes/lara-light-indigo/theme.css'
import { BrowserRouter } from 'react-router-dom'
import { Toaster } from 'react-hot-toast'
import { getApp } from './routes/utils/helpers'
import ScrollToTop from './components/ScrollToTop/ScrollToTop'

function App() {
  const CurrentApp = getApp()
  return (
    <BrowserRouter>
      <ScrollToTop>
        <CurrentApp />
      </ScrollToTop>
      <Toaster />
    </BrowserRouter>
  )
}

export default App
