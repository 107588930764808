// eslint-disable-next-line import/named
import { Product } from '@chec/commerce.js/types/product'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState: { products: Product[] } = {
  products: [],
}

const products = createSlice({
  name: 'products',
  initialState,
  reducers: {
    setProducts(state, action: PayloadAction<Product[]>) {
      state.products = action.payload
    },
  },
})

export const productsActions = products.actions
export const productsReducer = products.reducer
