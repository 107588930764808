import { Component, ErrorInfo, ReactNode } from 'react'
import EmptyState from '../EmptyState/EmptyState'
import errorImage from '../../assets/images/error boundary.png'
import { IoReload } from 'react-icons/io5'

interface Props {
  children: ReactNode
}

interface State {
  hasError: boolean
}

class ErrorBoundary extends Component<Props, State> {
  state = { hasError: false }

  static getDerivedStateFromError(error: Error) {
    return { hasError: true }
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    console.log(error, errorInfo)
  }

  render() {
    if (this.state.hasError) {
      return (
        <div
          style={{
            height: '100dvh',
          }}>
          <EmptyState
            icon={errorImage}
            text='Sorry.. There was an error'
            subText="We're not exactly sure what happened but something went wrong. Either refresh the page or return home to try again"
            buttonText='Reload'
            action={() => window.location.reload()}
            buttonIcon={<IoReload />}
          />
        </div>
      )
    }

    return this.props.children
  }
}

export default ErrorBoundary
