import { useState } from 'react'
import { toast } from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'
import { client } from '../../lib/client'
import { TeamAccount } from '../../models/teamData'
import { useAppDisPatch } from '../../redux/store'
import { teamActions } from '../../redux/teamSlice'
import { supabase } from '../../supabaseClient'

const useSignIn = () => {
  const [loading, setloading] = useState(false)
  const [fetchingUser, setfetchingUser] = useState(false)
  const navigate = useNavigate()

  const dispatch = useAppDisPatch()

  const signInUser = async (login: string, password: string) => {
    try {
      setloading(true)
      const { user } = await getUserInfo(login)

      if (user.length) {
        const { error } = await supabase.auth.signInWithPassword({
          email: user[0].email,
          password,
        })
        if (error) throw error

        toast.success('Signed In succesfully')
        navigate(`/${user[0].username}/`)
      } else {
        toast.error('Account does not exist')
      }
    } catch (error: any) {
      toast.error(error.error_description || error.message)
    } finally {
      setloading(false)
    }
  }

  const signInTeam = async (email: string, password: string) => {
    try {
      setloading(true)

      const { user } = await getAdminInfo(email)

      if (!user.length) {
        toast.error('Account Does not exist')
        return
      }

      const { error } = await supabase.auth.signInWithPassword({
        email,
        password,
      })

      if (error) throw error

      toast.success('Signed In succesfully')
      dispatch(teamActions.setAdmin(user[0]))
      navigate(`/dashboard/home`)
    } catch (error: any) {
      if (error.code === 'PGRST116') {
        toast.error('Account does not exist')
      } else {
        toast.error(error.error_description || error.message)
      }
    } finally {
      setloading(false)
    }
  }

  const getAdminInfo = async (email: string) => {
    const query = '*[_type == "team_account" && email == $email]'
    const params = { email: `${email}` }

    let response: TeamAccount[] = []

    try {
      setfetchingUser(true)
      response = await client.fetch(query, params)
    } catch (error) {
      toast.error('Something went wrong')
    } finally {
      setfetchingUser(false)
    }

    return { user: response }
  }

  const getUserInfo = async (info: string) => {
    const query = '*[_type == "user" && (email == $info || username == $info)]'
    const params = { info: `${info}` }

    let response: any[] = []

    try {
      setfetchingUser(true)
      response = await client.fetch(query, params)
    } catch (error) {
      toast.error('Something went wrong')
    } finally {
      setfetchingUser(false)
    }

    return { user: response }
  }

  const signOutUser = async () => {
    try {
      setloading(true)
      const { error } = await supabase.auth.signOut()

      if (error) throw error

      navigate('/login')
      dispatch(teamActions.setAdmin(null))
      dispatch(teamActions.setTeam(null))
    } catch (error) {
      toast.error('Something went wrong')
    } finally {
      setloading(false)
    }
  }

  return {
    signInUser,
    signInTeam,
    getAdminInfo,
    getUserInfo,
    loading,
    fetchingUser,
    signOutUser,
  }
}

export default useSignIn
