import { lazy, Suspense } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import {
  CHECKOUT,
  FORGOT,
  ONBOARDING,
  REGISTER,
  RESET,
  SIGNIN,
} from './RouteConstants'
import NotFound from '../components/Extras/NotFound'
import PageLoader from '../components/Loader/PageLoader'
import SignIn from '../views/SignIn/SignIn'
import TeamApp from '../views/Teams/TeamApp'

const Layout = lazy(() => import('../views/LandingPage/Layout/Layout'))
const Profile = lazy(() => import('../views/ProfilePage/Profile'))
const Checkout = lazy(() => import('../views/LandingPage/Checkout/Checkout'))
const Confirmation = lazy(
  () => import('../views/LandingPage/Checkout/CheckoutSteps/Confirmation')
)
const OrderFailed = lazy(
  () => import('../views/LandingPage/Checkout/CheckoutSteps/Failed')
)
const ProductLink = lazy(() => import('../views/Onboarding/ProductLink'))
const Onboarding = lazy(() => import('../views/Onboarding/Onboarding'))
const SignUp = lazy(() => import('../views/SignUp/SignUp'))
const ForgotPassword = lazy(() => import('../views/SignIn/ForgotPassword'))
const ResetPassword = lazy(() => import('../views/SignIn/ResetPassword'))
const TeamView = lazy(() => import('../views/Teams/TeamView'))
const LinkProduct = lazy(
  () => import('../views/Onboarding/OnboardingSteps/LinkProduct')
)

const RouteDom = () => {
  return (
    <Suspense fallback={<PageLoader />}>
      <Routes>
        <Route path='/home/*' element={<Layout />} />
        <Route path={`/${CHECKOUT}`} element={<Checkout />} />
        <Route path={`/${CHECKOUT}/successful`} element={<Confirmation />} />
        <Route path={`/${CHECKOUT}/failed`} element={<OrderFailed />} />
        <Route path={`/${ONBOARDING}/*`} element={<Onboarding />} />
        <Route path={`/${SIGNIN}`} element={<SignIn app='main' />} />
        <Route path={`/tm/${SIGNIN}`} element={<SignIn app='team' />} />
        <Route path={`/${REGISTER}/:id`} element={<SignUp />} />
        <Route path={`/${FORGOT}`} element={<ForgotPassword app='main' />} />
        <Route path={`/${RESET}`} element={<ResetPassword app='main' />} />
        <Route path={`/:username/*`} element={<Profile />} />
        <Route path={`/tm/*`} element={<TeamApp />} />
        <Route path={`/dashboard/*`} element={<TeamView />} />
        <Route path='/card/:id' element={<ProductLink />} />
        <Route path='/link/:id' element={<LinkProduct />} />
        <Route path='/notfound' element={<NotFound />} />
        <Route path='/*' element={<Navigate to='/home' />} />
      </Routes>
    </Suspense>
  )
}

export const ProfileRoutes = () => {
  return (
    <Suspense fallback={<PageLoader />}>
      <Routes>
        <Route path={`/`} element={<Navigate to={`${SIGNIN}`} />} />
        <Route path={`/${ONBOARDING}/*`} element={<Onboarding />} />
        <Route path={`/${SIGNIN}`} element={<SignIn app='profile' />} />
        <Route path={`/${REGISTER}/:id`} element={<SignUp />} />
        <Route path={`/${FORGOT}`} element={<ForgotPassword app='profile' />} />
        <Route path={`/${RESET}`} element={<ResetPassword app='profile' />} />
        <Route path={`/:username/*`} element={<Profile />} />
        <Route path='/card/:id' element={<ProductLink />} />
        <Route path='/link/:id' element={<LinkProduct />} />
        <Route path='/notfound' element={<NotFound />} />
      </Routes>
    </Suspense>
  )
}

export const TeamRoutes = () => {
  return <TeamApp />
}

export default RouteDom
