// eslint-disable-next-line import/named
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ProfileData } from '../types/profileData'

const initialState: { user: ProfileData } = {
  user: {
    _id: '',
    _rev: '',
    _type: '',
    account_type: '',
    id: '',
    fullname: '',
    phone: '',
    email: '',
    position: '',
    organization: '',
    biography: '',
    username: '',
    is_disabled: false,
    is_complete_setup: false,
    _createdAt: '',
    _updatedAt: '',
    products: [],
  },
}

const profile = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setUser(state, action: PayloadAction<any>) {
      state.user = { ...action.payload }
    },
    reset: () => initialState,
  },
})

export const profileActions = profile.actions
export const profileReducer = profile.reducer
