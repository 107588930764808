export const ONBOARDING = 'onboarding'
export const SIGNIN = 'login'
export const REGISTER = 'register'
export const PRODUCTS = 'products'
export const PRODUCT = 'product'
export const CHECKOUT = 'checkout'
export const HOW = 'how'
export const PROFILE = 'me'
export const ACTIVATE = 'activate'
export const ABOUT = 'about'
export const CAREERS = 'careers'
export const DESIGN = 'order/:id'
export const PRIVACY = 'privacy-policy'
export const TERMS = 'terms-of-service'
export const TEAMS = 'teams'
export const CONTACT = 'contact'
export const SUPPORT = 'support'
export const FORGOT = 'account/forgot-password'
export const RESET = 'account/reset-password'
export const NOTFOUND = 'not-found'
export const DISABLED = 'disabled'
