import { logos } from '../../assets'
import './loader.scss'

type LoadingProps = {
  loadingText?: string
  showText?: boolean
}

const PageLoader = ({
  loadingText = 'Loading...',
  showText = true,
}: LoadingProps) => {
  return (
    <div className='load'>
      <img src={logos.logoMark} alt='Loading...' />
    </div>
  )
}

export default PageLoader
