import logoPrimary from './logos/Main small.png'
import logoSecondary from './logos/dark small.png'
import logoWhite from './logos/whitesmall.png'
import logoMark from './logos/Symbol.png'
import teamsLogo from './logos/teams.svg'

import placeHolderImg from './images/Image Placeholder.png'
import card from './images/card.png'
import iphoneCard from './images/iphone with card.svg'
import bamboo from './images/bamboo trans.png'
import white from './images/white trans.png'

import verified from './icons/verified.png'
import chevron from './icons/chevron.png'
import plusCircle from './icons/plus-circle.svg'
import save from './icons/save.png'
import google from './icons/googleLogo.svg'
import apple from './icons/AppleLogo.svg'
import noGroups from './images/no groups.svg'
import noProfiles from './images/no profiles.svg'

import facebook from './icons/facebook.png'
import instagram from './icons/instagram.png'
import twitter from './icons/twitter.png'
import tiktok from './icons/tik_tok.png'
import telegram from './icons/telegram.png'
import snapchat from './icons/snapchat.png'
import whatsapp from './icons/whatsapp.png'
import linkedIn from './icons/linkedin.png'

import github from './icons/github.png'
import googleBusiness from './icons/google_my_business.png'
import discord from './icons/discord.png'
import dribble from './icons/dribbble.png'
import youtube from './icons/youtube.png'
import behance from './icons/behance.png'
import custom from './icons/custom_link.png'

import how1 from './images/choose card.png'
import how2 from './images/Activate Profile.png'
import how3 from './images/share and go.png'

import comingSoon from './images/coming soon banner.png'
import noPermit from './images/np permission.png'

export const logos = {
  logoMark,
  logoPrimary,
  logoSecondary,
  logoWhite,
  teamsLogo,
}

export const images = {
  placeHolderImg,
  card,
  white,
  bamboo,
  iphoneCard,
  how1,
  how2,
  how3,
  noGroups,
  noProfiles,
  comingSoon,
  noPermit,
}

export const icons = {
  chevron,
  verified,
  plusCircle,
  save,
  apple,
  google,
}

export const socialIcons = {
  facebook,
  instagram,
  twitter,
  telegram,
  tiktok,
  snapchat,
  whatsapp,
  linkedIn,
}

export const portfolioIcons = {
  github,
  googleBusiness,
  discord,
  dribble,
  youtube,
  behance,
  custom,
}
