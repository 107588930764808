// eslint-disable-next-line import/named
import { Cart } from '@chec/commerce.js/types/cart'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState: { cart: Partial<Cart>; showCart: boolean } = {
  cart: {},
  showCart: false,
}

const cart = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    toggleShowCart(state) {
      state.showCart = !state.showCart
    },
    setCart(state, actions: PayloadAction<Cart>) {
      state.cart = actions.payload
    },
  },
})

export const cartActions = cart.actions
export const cartReducer = cart.reducer
