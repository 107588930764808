import { configureStore } from '@reduxjs/toolkit'
// eslint-disable-next-line import/named
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import { profileReducer } from './profileSlice'
import { cartReducer } from './cartSlice'
import { productsReducer } from './productsSlice'
import { chaeckoutReducer } from './CheckoutSlice'
import { signUpReducer } from './signUpSlice'
import { teamReducer } from './teamSlice'

const store = configureStore({
  reducer: {
    profile: profileReducer,
    cart: cartReducer,
    products: productsReducer,
    checkout: chaeckoutReducer,
    signUp: signUpReducer,
    team: teamReducer,
  },
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export const useAppDisPatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
export default store
