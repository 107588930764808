// eslint-disable-next-line import/named
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Team, TeamAccount, TeamConnection } from '../models/teamData'

const initialState: {
  team: Team | null
  profiles: TeamAccount[]
  loading: boolean
  admin: TeamAccount | null
  connections: TeamConnection[]
} = {
  team: null,
  loading: false,
  admin: null,
  connections: [],
  profiles: [],
}

const team = createSlice({
  name: 'team',
  initialState,
  reducers: {
    setTeam(state, action: PayloadAction<any>) {
      state.team = { ...action.payload }
    },
    setAdmin(state, action: PayloadAction<any>) {
      state.admin = { ...action.payload }
    },
    setProfiles(state, action: PayloadAction<any>) {
      state.profiles = action.payload
    },
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload
    },
    setConnections(state, action: PayloadAction<any>) {
      state.connections = action.payload
    },
    reset: () => initialState,
  },
})

export const teamActions = team.actions
export const teamReducer = team.reducer
