import { yupResolver } from '@hookform/resolvers/yup'
import { Session } from '@supabase/supabase-js'
import { AnimatePresence, motion } from 'framer-motion'
import { FC } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { Link, useNavigate } from 'react-router-dom'
import * as yup from 'yup'
import { logos } from '../../assets'
import Button from '../../components/Button/Button'
import Helmet from '../../components/Helmet/Helmet'
import FormInput from '../../components/Shared/FormInput/FormInput'
import useRetrieveSession from '../../hooks/Auth/useRetrieveSession'
import useSignIn from '../../hooks/Auth/useSignIn'
import { FORGOT } from '../../routes/RouteConstants'
import { SharedApplication } from './ForgotPassword'
import styles from './signIN.module.scss'
import { useAppDisPatch } from '../../redux/store'
import { teamActions } from '../../redux/teamSlice'

export type LoginInfo = {
  email: string
  password: string
}

const signInSchema = yup.object({
  email: yup.string().required('Enter email').email(`Enter a valid email`),
  password: yup.string().required('Enter password'),
})

const initialState: LoginInfo = {
  email: '',
  password: '',
}

const SignIn: FC<SharedApplication> = ({ app }) => {
  const year = new Date().getFullYear()

  const dispatch = useAppDisPatch()

  const formMethods = useForm<LoginInfo>({
    resolver: yupResolver(signInSchema),
    defaultValues: initialState,
  })

  const navigate = useNavigate()

  const {
    signInUser,
    loading,
    signInTeam,
    getAdminInfo,
    getUserInfo,
    fetchingUser,
  } = useSignIn()

  const navigateToSignedInAccount = async (session: Session | null) => {
    if (!session || !session.user.email) return

    if (app === 'team') {
      const { user } = await getAdminInfo(session.user.email)

      dispatch(teamActions.setAdmin(user[0]))

      if (user.length) navigate(`/dashboard`)
    } else {
      const { user } = await getUserInfo(session.user.email)

      if (user.length) navigate(`/${user[0].username}/`)
    }
  }

  const { isLoading, currentSession } = useRetrieveSession(
    navigateToSignedInAccount
  )

  const submitHandler: SubmitHandler<LoginInfo> = ({ email, password }) => {
    if (app === 'team') {
      signInTeam(email, password)
    } else {
      signInUser(email, password)
    }
  }

  return (
    <Helmet title='Sign In'>
      <AnimatePresence>
        {isLoading || (currentSession && fetchingUser) ? (
          <motion.div
            initial={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className={styles.introBackground}>
            <img src={logos.logoWhite} alt='' />
            <p>
              By <br />
              Zavier Essentials
            </p>
          </motion.div>
        ) : (
          <div className={styles.page}>
            <div className={styles.logoBox}>
              <img src={logos.logoPrimary} alt='' />
            </div>
            <div className={styles.header}>
              <h1>Welcome back!</h1>
              <h3>Enter your details to access your account</h3>
            </div>
            <form onSubmit={formMethods.handleSubmit(submitHandler)}>
              <FormInput
                type='text'
                formMethods={formMethods}
                label='Email'
                inputName='email'
                placeHolder='me@example.com'
                required
              />

              <FormInput
                type='password'
                formMethods={formMethods}
                label='Password'
                inputName='password'
                placeHolder='**********'
                required
              />
              <Link className={styles.forgot} to={`/${FORGOT}`}>
                Forgot password?
              </Link>

              <Button
                text='Log In'
                className={styles.signIn__btn}
                type='submit'
                loading={loading || fetchingUser}
              />
            </form>

            <p className={styles.info}>
              Don't have an account?{' '}
              <a href='https://www.snnap.co/home/products' rel='noreferrer'>
                Get your card
              </a>
            </p>

            <div>
              <p>Snnap - Digital business card</p>
              <p>Version 2.5.0 - &copy; {year} Snnap</p>
            </div>
          </div>
        )}
      </AnimatePresence>
    </Helmet>
  )
}

export default SignIn
