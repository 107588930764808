import React, { FC, ReactNode } from 'react'
import loader from '../../assets/icons/loaderPrimary.svg'

interface Props {
  text?: string
  icon?: ReactNode
  loading?: boolean
  disabled?: boolean
  className: string
  onClick?: () => void
  type?: 'button' | 'submit' | 'reset'
  showLoadingText?: boolean
  showLoadingIcon?: boolean
  style?: any
}

const Button: FC<Props> = ({
  text,
  icon,
  loading,
  disabled,
  className,
  style,
  onClick,
  type = 'button',
  showLoadingText = false,
  showLoadingIcon = true,
}) => {
  return (
    <button
      className={className}
      style={{ ...style, position: 'relative' }}
      type={type}
      disabled={loading || disabled}
      onClick={onClick}>
      {icon && icon}
      {loading && showLoadingText && <>Loading...</>}
      {!loading && <>{text}</>}
      {loading && showLoadingIcon && (
        <img src={loader} alt='' style={{ width: '20px' }} />
      )}
    </button>
  )
}

export default Button
