import React, { FC, ReactNode, useRef, useState } from 'react'
import { IoIosEye } from 'react-icons/io'
import styles from './formInput.module.scss'
import { AiFillQuestionCircle } from 'react-icons/ai'
import { OverlayPanel } from 'primereact/overlaypanel'

interface Props {
  groupClass?: string
  disabledClass?: string
  formMethods?: any
  inputName: string
  type: string
  label: string
  placeHolder?: string
  icon?: string
  errorClass?: string
  disabled?: boolean
  required?: boolean
  tooltip?: ReactNode
}

const FormInput: FC<Props> = ({
  groupClass = styles.formGroup,
  errorClass = styles.errorClass,
  type,
  disabledClass = styles.formGroup,
  inputName,
  placeHolder,
  label,
  disabled = false,
  required = false,
  formMethods,
  tooltip,
}) => {
  const [changeType, setTypeChange] = useState(type)
  const op = useRef<any>(null)
  return (
    <div
      className={
        formMethods.formState.errors[inputName]
          ? errorClass
          : disabled
          ? disabledClass
          : groupClass
      }>
      <label htmlFor={inputName}>
        {label}
        {required && <span>*</span>}
      </label>
      <div>
        <input
          placeholder={placeHolder}
          {...formMethods.register(inputName)}
          id={inputName}
          type={changeType}
          disabled={disabled}
        />
        {type === 'password' && (
          <button
            type='button'
            onClick={() =>
              setTypeChange(changeType === 'password' ? 'text' : 'password')
            }>
            <IoIosEye />
          </button>
        )}
        <span></span>
      </div>
      {!!formMethods.formState.errors && (
        <small>{formMethods.formState.errors[inputName]?.message}</small>
      )}
      {tooltip && (
        <article>
          <button type='button' onClick={(e) => op.current.toggle(e)}>
            <AiFillQuestionCircle />
          </button>
          <OverlayPanel ref={op}>{tooltip}</OverlayPanel>
        </article>
      )}
    </div>
  )
}

export default FormInput
