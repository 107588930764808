// eslint-disable-next-line import/named
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { PurchasedProductTypes } from '../types/profileData'

interface SignUpUserData {
  username: string
}

const initialState: { card?: PurchasedProductTypes; user: SignUpUserData } = {
  user: { username: '' },
}

const signUp = createSlice({
  name: 'signup',
  initialState,
  reducers: {
    setUser(state, action: PayloadAction<any>) {
      state.user = { ...action.payload }
    },
    setCard(state, action: PayloadAction<any>) {
      state.card = { ...action.payload }
    },
    reset: () => initialState,
  },
})

export const signUpActions = signUp.actions
export const signUpReducer = signUp.reducer
