import React, { Suspense, lazy } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import PageLoader from '../../components/Loader/PageLoader'
import { FORGOT, RESET, SIGNIN } from '../../routes/RouteConstants'
import SignIn from '../SignIn/SignIn'
import NotFound from '../../components/Extras/NotFound'

const ForgotPassword = lazy(() => import('../SignIn/ForgotPassword'))
const ResetPassword = lazy(() => import('../SignIn/ResetPassword'))
const TeamView = lazy(() => import('../Teams/TeamView'))
const TeamProfile = lazy(() => import('./TeamProfile'))
const TeamProfileDetails = lazy(() => import('./TeamProfileDetails'))

const TeamApp = () => {
  return (
    <Suspense fallback={<PageLoader />}>
      <Routes>
        <Route path={`/`} element={<Navigate to={`/dashboard`} />} />
        <Route path={`/${SIGNIN}`} element={<SignIn app='team' />} />
        <Route path={`/${FORGOT}`} element={<ForgotPassword app='team' />} />
        <Route path={`/${RESET}`} element={<ResetPassword app='team' />} />
        <Route path={`/dashboard/*`} element={<TeamView />} />
        <Route path='/:id/:card' element={<TeamProfile />} />
        <Route path='/team/:id' element={<TeamProfileDetails />} />
        <Route path='/notfound' element={<NotFound />} />
      </Routes>
    </Suspense>
  )
}

export default TeamApp
