import { FC, ReactNode } from 'react'
import Button from '../Button/Button'
import { HiOutlinePlusSmall } from 'react-icons/hi2'

interface Props {
  icon?: string
  text: string
  subText?: string
  action?: () => void
  buttonText?: string
  buttonIcon?: ReactNode
}

const EmptyState: FC<Props> = ({
  text,
  icon,
  subText,
  action,
  buttonText,
  buttonIcon = <HiOutlinePlusSmall />,
}) => {
  return (
    <div className='empty-state'>
      <div className='empty-state-icon'>
        <img src={icon} alt='' />
      </div>
      <h3 className='empty-state-title'>{text}</h3>
      {subText && <p className='empty-state-subtitle'>{subText}</p>}
      {action && (
        <div className='empty-state-actions'>
          <Button
            onClick={action}
            className=''
            text={buttonText}
            icon={buttonIcon}
          />
        </div>
      )}
    </div>
  )
}

export default EmptyState
