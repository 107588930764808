import { useEffect, useState } from 'react'
import { supabase } from '../../supabaseClient'
import { Session } from '@supabase/supabase-js'

const useRetrieveSession = (
  callback?: (arg: Session | null) => Promise<void>
) => {
  const [isLoading, setIsLoading] = useState(true)
  const [currentSession, setCurrentSession] = useState<Session | null>(null)

  useEffect(() => {
    supabase.auth
      .getSession()
      .then(({ data: { session } }) => {
        setCurrentSession(session)
        if (callback) callback(session)
      })
      .finally(() => {
        setIsLoading(false)
      })

    supabase.auth.onAuthStateChange((_event, session) => {
      setCurrentSession(session)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    isLoading,
    currentSession,
  }
}

export default useRetrieveSession
