// eslint-disable-next-line import/named
import { CheckoutToken } from '@chec/commerce.js/types/checkout-token'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { CustomerInformation } from '../models/checkout'
import { ShippingMethod } from '@chec/commerce.js/types/shipping-method'

const initialState: {
  token: CheckoutToken | null
  customer: CustomerInformation
  shippingOption: ShippingMethod | null
} = {
  token: null,
  customer: {
    firstname: '',
    lastname: '',
    country: '',
    state: '',
    shipping_address: '',
    zip: '',
    city: '',
    email: '',
    phone: '',
  },
  shippingOption: null,
}

const checkout = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    setToken(state, action: PayloadAction<CheckoutToken>) {
      state.token = action.payload
    },
    setCustomerInfo(state, action: PayloadAction<CustomerInformation>) {
      state.customer = action.payload
    },
    setShippingMethod(state, action: PayloadAction<ShippingMethod | null>) {
      state.shippingOption = action.payload
    },
  },
})

export const checkoutActions = checkout.actions
export const chaeckoutReducer = checkout.reducer
