import React, { ReactNode } from 'react'
import { Helmet as ReactHelmet } from 'react-helmet'
type Props = {
  title: string
  children: ReactNode
}

const Helmet = ({ title, children }: Props) => {
  return (
    <div className='w-100'>
      <ReactHelmet>
        <title>
          {title} - Snnap NFC Business Cards for Seamless Networking
        </title>
        <meta
          property='og:title'
          content={`${title} - Snnap NFC Business Cards for Seamless Networking`}
        />
        <meta
          property='og:description'
          content={
            'Digital Business card in Nigeria. Upgrade your networking by sharing information instantly and seamlessly.'
          }
        />
        <meta property='og:url' content='https://snnap.co/' />
        <meta property='og:type' content='website' />
      </ReactHelmet>
      {children}
    </div>
  )
}

export default Helmet
