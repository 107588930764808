import React from 'react'
import styles from './extras.module.scss'
import Button from '../Button/Button'
import { useNavigate } from 'react-router-dom'
import { MdOutlineKeyboardBackspace } from 'react-icons/md'

const NotFound = () => {
  const navigate = useNavigate()
  return (
    <div className={styles.page}>
      <h1 className={styles.header}>404</h1>
      <p className={styles.text}>
        Sorry but the page you're looking for does not exist, has been removed,
        name changed or is temporarily unavailable
      </p>
      <Button
        className='button-primary'
        text='Go Back'
        icon={<MdOutlineKeyboardBackspace />}
        onClick={() => navigate(-1)}
      />
    </div>
  )
}

export default NotFound
