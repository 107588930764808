import RouteDom, { ProfileRoutes, TeamRoutes } from '../Routes'

export const APPS = [
  {
    subDomain: 'www',
    app: RouteDom,
    main: true,
  },
  {
    subDomain: 'me',
    app: ProfileRoutes,
    main: false,
  },
  {
    subDomain: 'team',
    app: TeamRoutes,
    main: false,
  },
]
